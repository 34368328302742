import Vue from 'vue'
import Vuex from 'vuex'
import {firestore, app, auth} from '@/plugins/firebase'
import Axios from 'axios'
import i18n from "@/plugins/i18n"
import nav from '@/navigation'
import dayjs from 'dayjs'
import JWT from 'jsonwebtoken'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  defaultLogo: 'https://firebasestorage.googleapis.com/v0/b/ksong-eb5c0.appspot.com/o/images%2Flogo%2Flogo.png?alt=media&token=a99086aa-a20a-41a9-8cc4-6bec7512088b',
  notFoundImage: 'https://firebasestorage.googleapis.com/v0/b/ksong-eb5c0/o/Assets%2Fno_image.jpg?alt=media&token=cbad99bb-8101-4631-9d77-8951ff65f27b',
  locale: 'zh-tw',
  themeColor: {},
  currency: '',
  allLocale: ['zh-tw'],
  navigation: [
    {
      _name: 'CSidebarNav',
      _children: []
    }
  ],
  pinNavigation: [
    {
      _name: 'CSidebarNav',
      _children: []
    }
  ],
  user: {
    isLogin: false,
    uid: '',
    token: '',
    data: {
      metadata: {}
    },
    permission: {
      Navigation: {}
    }
  },
  notification: [],
  userProjectApp: null,
  orderStatusStatistics: {
    Unpaid: {},
    UnShipment: {},
    EnableShipment: {},
    Abnormal: {},
    EnableRefund: {}
  },
  landingPageList: [],
  storeList: [],
  project: {}
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  setAuth(state, _auth) {
    if (_auth) {
      state.user.uid = _auth.uid
      state.user.data = {
        displayName: _auth.displayName,
        photoURL: _auth.photoURL,
        email: _auth.email,
        emailVerified: _auth.emailVerified,
        phoneNumber: _auth.phoneNumber,
        isAnonymous: _auth.isAnonymous,
        metadata: _auth.metadata,
      }
    } else {
      state.user.uid = ''
      state.user.token = ''
      state.user.data = {
        metadata: {}
      }
    }
  },
  setLogin(state, value) {
    state.user.isLogin = value;
  },
  setToken(state, token) {
    state.user.token = token
  },
  setPermission(state, value) {
    state.user.permission = value
    state.currency = value.Currency || ''
  },
  setNavigation(state, value) {
    if (value) {
      state.navigation[0]._children = []
      // 固定選單
      if (value.PinnedNavigation && value.PinnedNavigation.length > 0) {
        state.navigation[0]._children.push({
          _name: 'CSidebarNavTitle',
          _navName: 'Pinned',
          _children: ['Pinned']
        })
        value.PinnedNavigation.forEach(data => {
          const CurrentNavigationData = nav(state.locale).find(item => item._navName === data.Name)
          if (CurrentNavigationData) {
            switch (CurrentNavigationData._name) {
              case 'CSidebarNavItem':
              case 'AlterSidebarNavItem':
                state.navigation[0]._children.push({
                  ...CurrentNavigationData,
                  pinned: true
                })
                break
              case 'CSidebarNavDropdown':
              case 'AlterSidebarNavDropdown':
                state.navigation[0]._children.push({
                  ...CurrentNavigationData,
                  pinned: true,
                  items: CurrentNavigationData.items.filter(item => value.Navigation[data.Type].includes((item.permissionLabel)))
                })
                break
            }
          }
        })
        state.navigation[0]._children.push({
          _name: 'CSidebarNavDivider',
          _navName: 'Other/Divider',
          _class: 'm-2'
        })
      }
      // 基礎選單
      const AllNavItemTitle = Object.keys(value.Navigation)
      nav(state.locale).forEach((data) => {
        const navName = data._navName.split('/')
        let navTitle, navItem
        if (navName) {
          navTitle = navName[0]
          navItem = navName[1]
        }
        switch (data._name) {
          case 'CSidebarNavTitle':
            if (AllNavItemTitle.includes(navTitle)) {
              state.navigation[0]._children.push(data)
            }
            break
          case 'CSidebarNavItem':
          case 'AlterSidebarNavItem':
            if (value.Navigation[navTitle] && value.Navigation[navTitle].includes(navItem)) {
              state.navigation[0]._children.push(data)
            }
            break
          case 'CSidebarNavDropdown':
          case 'AlterSidebarNavDropdown':
            if (value.Navigation[navTitle] && value.Navigation[navTitle].includes(navItem)) {
              state.navigation[0]._children.push(data)
            }
            if (
                typeof value.Navigation[navTitle] !== 'undefined' &&
                typeof state.navigation[0]._children[(state.navigation[0]._children.length - 1)].items !== 'undefined'
            ) {
              // 取得目前存在的選單鍵值
              const NowNavItemIndex = (state.navigation[0]._children.length - 1)
              state.navigation[0]._children[NowNavItemIndex].items = state.navigation[0]._children[NowNavItemIndex].items.filter(data2 => {
                return value.Navigation[navTitle].includes(data2.permissionLabel)
              })
            }
            break
          default:
            state.navigation[0]._children.push(data)
            break
        }
      })
      return true
    }
    state.navigation[0]._children = []
  },
  setUserProjectApp(state, app) {
    state.userProjectApp = app
  },
  setThemeColor(state, themeColor) {
    state.themeColor = themeColor
  },
  setProject(state, payloads) {
    state.project = payloads || {}
  },
  resetNotification(state) {
    state.notification = []
  },
  setNotification(state, data) {
    data.forEach((notification) => {
      state.notification.push(notification)
    })
  },
  setOrderStatusStatistics(state, data) {
    const OrderStatusStatistics = {
      Unpaid: data.filter(item => item._id.PaymentStatus === 'U' || item._id.PaymentStatus === 'N'),
      UnShipment: data.filter(item => item._id.LogisticsStatus === 'P'),
      EnableShipment: data.filter(item => item._id.LogisticsStatus === 'P' && (item._id.PaymentStatus === 'Y' || item._id.PaymentStatus === 'A')),
      Abnormal: data.filter(item => item._id.LogisticsStatus === 'X'),
      EnableRefund: data.filter(item => (item._id.LogisticsStatus === 'R' || item._id.LogisticsStatus === 'RF') && (item._id.PaymentStatus === 'Y' || item._id.PaymentStatus === 'C')),
      UnprocessedMessage: [],
    }
    Object.keys(OrderStatusStatistics).forEach(type => {
      state.orderStatusStatistics[type] = {
        TotalItems: OrderStatusStatistics[type].map(item => item.TotalItems).reduce((a, c) => a + c, 0),
        TotalAmount: OrderStatusStatistics[type].map(item => item.TotalAmount).reduce((a, c) => a + c, 0)
      }
    })
  },
  setLandingPageList (state, data) {
    state.landingPageList = data || []
  },
  setStoreList (state, data) {
    state.storeList = data || []
  }
}

const actions = {
  async InnerRequest({dispatch, state}, data) {
    let idToken
    if (!localStorage.getItem('XX-CSRF-Token')) {
      idToken = await auth.currentUser.getIdToken(true).then((idToken) => {
        return idToken
      })
    }
    return Axios.create({
      baseURL: data.endPoint || '/api',
      timeout: 120000,
      headers: {
        'locale': state.locale,
        // 'xx-csrf-token': idToken
        'xx-csrf-token': localStorage.getItem('XX-CSRF-Token') || idToken,
        ...(data.headers || {}),
      }
    }).request(data).then((response) => {
      const { config, data: result, status, statusText } = response
      dispatch('APIRecord', {
        url: config.url,
        method: config.method,
        body: config.data,
        idToken: config.headers['xx-csrf-token'],
        status,
        statusText,
        response: result
      })
      return response
    }).catch((err) => {
      console.log(err)
      const { config, data: result, status, statusText } = err.response
      dispatch('APIRecord', {
        url: config.url,
        method: config.method,
        body: config.data,
        idToken: config.headers['xx-csrf-token'],
        status,
        statusText,
        response: result
      })
      const Code = result.code || result.Code
      switch (Code) {
        case 'auth/id-token-expired':
        case 'auth/id-token-revoked':
        case 'auth/argument-error':
          dispatch('Logout')
          throw {
            code: (result.code || result.Code),
            msg: i18n.messages[state.locale].Message[(result.code || result.Code)],
            type: 'warn'
          }
        default:
          throw {
            code: (result.code || result.Code || 'Global/SystemError'),
            msg: (result.message || result.Message || result || err),
            type: 'warn'
          }
      }
    })
  },
  GetOrderStatus ({ dispatch, commit, state }) {
    return dispatch('InnerRequest', {
      endPoint: '/service',
      url: '/order/reporting/orderStatus',
      method: 'post',
      data: {
        Project: state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
        StartTime: dayjs().subtract(60, 'day').unix(),
      },
    }).then(({ data }) => {
      commit('setOrderStatusStatistics', data.Data)
      return data
    }).catch((error) => {
      throw error
    })
  },
  GetLandingPageList ({ dispatch, commit }) {
    return dispatch('InnerRequest', {
      url: '/page/shop/list',
      method: 'get',
      params: {
        page: 1,
        perPage: 100,
        order: 'nto'
      },
    }).then(({ data }) => {
      const landingList = [
        { label: i18n.messages[state.locale].Offer.OfficialWeb, value: 'Default' }
      ]
      data.list.forEach((item) => {
        landingList.push({
          _id: item._id,
          Name: item.Name,
          Slug: item.Slug,
          Cover: item.Cover,
          Introduction: item.Introduction,
          Variable: {
            PaymentFeePercentage: item.Variable.PaymentFeePercentage,
            ShipmentFeePercentage: item.Variable.ShipmentFeePercentage,
            CommissionPercentage: item.Variable.CommissionPercentage
          },
          label: `${item.Name} (${item.Slug})`,
          value: 'SinglePage-' + item.Slug
        })
      })
      commit('setLandingPageList', landingList)
    })
  },
  GetStoreList ({ dispatch, commit }) {
    const Permission = localStorage.getItem('Permission') ? JSON.parse(localStorage.getItem('Permission')) : {}
    const FeaturePermission = Permission.Features && Permission.Features.Order && Permission.Features.Order ? Permission.Features.Order : []
    if (FeaturePermission.includes('StorePermission')) {
      return dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/availableList',
          Data: {
            Taxonomy: 'MENU'
          }
        }
      }).then(({ data }) => {
        commit('setStoreList', data.Data.map(item => {
          return {
            _id: item._id,
            Name: item.Name,
            Slug: item.Slug,
            Cover: item.Cover,
            label: `${item.Name}`,
            value: item.Slug
          }
        }))
      })
    }
    return Promise.resolve()
  },
  CheckAuth({commit}, user) {
    return new Promise((resolve, reject) => {
      commit("setLogin", user !== null)
      if (user !== null) {
        commit("setAuth", user)
        resolve({
          message: 'Login/Success'
        })
      } else {
        commit("setAuth", null)
        reject({
          message: 'Login/Unauthorized'
        })
      }
    })
  },
  GetPermission({commit}, uid) {
    //todo 改成用api請求
    return firestore.collection('Permission').doc(uid).get().then((doc) => {
      commit('setPermission', doc.data())
      localStorage.setItem('Permission', JSON.stringify(doc.data()))
      return doc.data()
    }).catch((err) => {
      throw err
    })
  },
  SetPermission({commit}, data) {
    return new Promise((resolve, reject) => {
      if (data !== false) {
        commit('setPermission', data)
        resolve(true)
      } else {
        reject(false)
      }
    })
  },
  AppSetting({commit}, appSetting) {
    return new Promise((resolve, reject) => {
      if (appSetting) {
        commit('setUserProjectApp', app.initializeApp(appSetting, 'userProjectApp'))
        resolve(true)
      } else {
        commit('setUserProjectApp', app)
        reject('App setting is used on default.')
      }
    })
  },
  Logout({commit}) {
    return auth.signOut().then(() => {
      commit('setAuth', null)
      commit('setPermission', {})
      commit('setLogin', false)
      commit('setNavigation', false)
      commit('setUserProjectApp', null)
      commit('setNotification', [])
      localStorage.removeItem('Permission')
      localStorage.removeItem('XX-CSRF-Token')
      app.app().delete().then(() => true)
      window.location.reload()
    }).catch((err) => {
      throw err
    })
  },
  GetNotification({commit, state}, endAtTime) {
    let Instance = state.userProjectApp.database().ref(`/Notification`).orderByChild('CreateTime').limitToLast(10)
    if (endAtTime && typeof endAtTime === 'number') {
      Instance = Instance.endAt(endAtTime)
    }
    return Instance.on('value', (result) => {
      if (typeof endAtTime === 'undefined') {
        commit('resetNotification')
      }
      const Result = []
      result.forEach(function (item) {
        Result.push({
          ...item.val(),
          ID: item.key,
          CreateTime: dayjs.unix(item.val().CreateTime).format('YYYY-MM-DD HH:mm:ss')
        })
        //判斷是否已經通知過
        // if (!item.val().IsNotify && !endAtTime) {
        //   dispatch('InnerRequest', {
        //     url: '/notify/IsNotifyManage',
        //     method: 'post',
        //     data: {
        //       ID: item.key
        //     }
        //   }).then(() => {
        //     Vue.notify({
        //       group: 'notify',
        //       title: item.val().Title,
        //       text: item.val().Content,
        //       type: item.val().Type.toLowerCase(),
        //       position: 'bottom right',
        //       duration: 10000,
        //       speed: 600
        //     })
        //   })
        // }
      })
      Result.reverse()
      commit('setNotification', Result)
      return Result.length
    })
  },
  getIdToken({commit}, user) {
    return user.getIdToken(true).then((idToken) => {
      commit('setToken', idToken)
      localStorage.setItem('XX-CSRF-Token', idToken)
    }).catch((err) => {
      console.error('e1:' + err)
      throw err
    })
  },
  PinNavigation ({ commit }, data) {
    const PinData = {
      Name: data.Type + '/' + data.Item,
      Type: data.Type,
      Item: data.Item
    }
    let PinNavigationList = []
    if (localStorage.getItem('PinNavigationList')) {
      PinNavigationList = JSON.parse(localStorage.getItem('PinNavigationList'))
    }
    if (!PinNavigationList.map(item => item.Name).includes(PinData.Name) && !data.Pin) {
      PinNavigationList.push(PinData)
    }
    if (PinNavigationList.map(item => item.Name).includes(PinData.Name) && data.Pin) {
      PinNavigationList.splice(PinNavigationList.findIndex(item => item.Name === PinData.Name), 1)
    }
    localStorage.setItem('PinNavigationList', JSON.stringify(PinNavigationList))
    const Permission = JSON.parse(localStorage.getItem('Permission'))
    commit('setNavigation', {
      ...Permission,
      PinnedNavigation: localStorage.getItem('PinNavigationList') ? JSON.parse(localStorage.getItem('PinNavigationList')) : null
    })
  },
  APIRecord(context, payload = {}) {
    const claims = JWT.decode(payload.idToken) || {}
    const recordURI = [
        'add',
        'edit',
        'update',
        'delete',
        'remove',
        'send',
        'set',
        'duplicate',
        'refund',
        'sync',
        'action',
        'addLogisticsTracking',
        'createLogisticsOrder',
        'bulkCreateLogisticsOrder',
        'bulkImportLogisticsData',
        'exportShipmentList',
        'importOrder',
        'importOrderProducts',
        'adminCreate',
        'issueInvoice',
        'exportShipmentLabel',
        'exportOrderCSV',
        'exportOrderDetailCSV'
    ]
    if (!recordURI.some((uri => payload.url.includes(uri)))) {
      return true
    }
    return Axios.request({
      baseURL: '/records',
      url: '/query/record',
      method: 'post',
      timeout: 120000,
      data: {
        Project: context.state.user.permission.Project,
        Origin: 'Manage',
        Class: 'M',
        Path: payload.url,
        Method: payload.method,
        Body: typeof payload.body === 'object' ? JSON.stringify(payload.body) : payload.body,
        VisitID: '0',
        UserID: claims.user_id,
        UserName: claims.name,
        UserAccount: claims.email,
        Status: payload.status,
        Error: payload.statusText !== 'OK',
        Response: typeof payload.response === 'object' ? JSON.stringify(payload.response) : payload.response,
      }
    }).then(({ data }) => {
      return data
    }).catch((error) => {
      throw error
    })
  },
}

const getters = {
  user(state) {
    return state.user
  },
  navigation(state) {
    return state.navigation
  },
  orderStatusStatistics(state) {
    return state.orderStatusStatistics
  },
  landingPageList(state) {
    return state.landingPageList || []
  },
  storeList(state) {
    return [
      {
        label: '網路商店',
        value: state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project
      },
      ...state.storeList
    ] || []
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
