// Dependencies
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import dayjs from 'dayjs'
import xlsx from 'xlsx'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Asia/Taipei')

// Plugin
import './plugins/axios'
import './plugins/notification'
import './plugins/progressbar'
import './plugins/theme'
import './plugins/datetimepicker'
import './plugins/lazyload'
import { auth } from './plugins/firebase'

// Css
import './assets/scss/global.scss'
import './assets/scss/editor.scss'
import './assets/scss/builder.scss'
import { iconsSet as icons } from './assets/icons/icons.js'
import '@fortawesome/fontawesome-free/css/all.min.css'

// Components
import AlterSidebarNavDropdown from './components/public/AlterSidebarNavDropdown'
import AlterSidebarNavItem from './components/public/AlterSidebarNavItem'

Vue.component('AlterSidebarNavDropdown', AlterSidebarNavDropdown)
Vue.component('AlterSidebarNavItem', AlterSidebarNavItem)

Vue.prototype.$dayjs = dayjs
Vue.config.productionTip = false
Vue.prototype.$xlsx = xlsx
Vue.prototype.$amount = new Intl.NumberFormat()

// 取得管理員登入狀態
auth.onAuthStateChanged(user => {
  store.dispatch("CheckAuth", user).catch((err) => {
    if (err.message === 'Login/Unauthorized' && router.app.$route.name !== 'login') {
      router.replace({
        path: '/login'
      }).then(() => {
        Vue.notify({
          group: 'notify',
          title: i18n.messages[store.state.locale].Message.Warning,
          text: i18n.messages[store.state.locale].Message['Login/Unauthorized'],
          type: 'warn'
        })
      })
    }
    window.clearTimeout()
  }).then(() => {
    if (store.getters.user.isLogin === true) {
      //處理導向
      if (router.app.$route.name === 'login') {
        router.replace('/')
      }
      //發送token至server驗證狀態
      store.dispatch('getIdToken', user).then(() => {
        Promise.all([
          //載入專案自定的語系資料
          store.dispatch('InnerRequest', {
            url: '/system/get/Locales',
            method: 'get',
          }).then(({data}) => {
            if (typeof data === 'object') {
              Object.keys(data).forEach((locale) => {
                const i18nMessage = i18n.messages[locale]
                Object.keys(data[locale]).forEach((index) => {
                  Object.keys(data[locale][index]).forEach((subIndex) => {
                    i18nMessage[index.replace('-', '/')][subIndex] = data[locale][index][subIndex]
                  })
                })
                i18n.setLocaleMessage(locale, i18nMessage)
              })
            }
          })
        ])
      }).catch((err) => {
        Vue.notify({
          group: 'notify',
          title: i18n.messages[store.state.locale].Message.Error,
          text: i18n.messages[store.state.locale].Message['Global/Unexpected'] + ': ' + err,
          type: 'error'
        })
      })

      //定時發送token至server驗證狀態
      window.setInterval(() => {
        store.dispatch('getIdToken', user).catch((err) => {
          Vue.notify({
            group: 'notify',
            title: i18n.messages[store.state.locale].Message.Error,
            text: i18n.messages[store.state.locale].Message['Global/Unexpected'] + ': ' + err,
            type: 'error'
          })
        })
      }, 60000 * 50)

      //定時取得訂單狀態筆數
      // window.setInterval(() => {
      //   store.dispatch('GetOrderStatus')
      // }, 60000 * 10)

      //取得權限
      let PermissionAction
      if (!localStorage.getItem('Permission')) {
        PermissionAction = store.dispatch('GetPermission', user.uid).then((response) => {
          return Promise.all([
            store.dispatch('GetLandingPageList'),
            store.dispatch('GetStoreList'),
            store.dispatch('InnerRequest', {
              url: '/web/project',
              method: 'get',
              headers: {
                'xx-csrf-token': '',
                domain: JSON.parse(localStorage.getItem('Permission')) && JSON.parse(localStorage.getItem('Permission')).Domain
              }
            }).then(({ data }) => {
              store.commit('setProject', data.Data)
            }),
            store.dispatch('GetOrderStatus').then(() => {
              store.commit('setNavigation', {
                ...response,
                PinnedNavigation: localStorage.getItem('PinNavigationList') ? JSON.parse(localStorage.getItem('PinNavigationList')) : null
              })
            }),
            store.dispatch('AppSetting', response.AppSetting)
          ]).then(() => {
            store.dispatch('GetNotification')
          })
        }).catch((error) => {
          throw error
        })
      } else {
        const Permission = JSON.parse(localStorage.getItem('Permission'))
        PermissionAction = store.dispatch('SetPermission', Permission).then(() => {
          return Promise.all([
            store.dispatch('GetLandingPageList'),
            store.dispatch('GetStoreList'),
            store.dispatch('InnerRequest', {
              url: '/web/project',
              method: 'get',
              headers: {
                'xx-csrf-token': '',
                domain: JSON.parse(localStorage.getItem('Permission')) && JSON.parse(localStorage.getItem('Permission')).Domain
              }
            }).then(({ data }) => {
              store.commit('setProject', data.Data)
            }).catch(() => {
              return true
            }),
            store.dispatch('GetOrderStatus').then(() => {
              store.commit('setNavigation', {
                ...Permission,
                PinnedNavigation: localStorage.getItem('PinNavigationList') ? JSON.parse(localStorage.getItem('PinNavigationList')) : null
              })
            }),
            store.dispatch('AppSetting', Permission.AppSetting)
          ]).then(() => {
            store.dispatch('GetNotification')
          })
        }).catch((error) => {
          throw error
        })
      }
      PermissionAction.catch((error) => {
        console.error(error)
        Vue.notify({
          group: 'notify',
          title: i18n.messages[store.state.locale].Message.Error,
          text: i18n.messages[store.state.locale].Message['Global/Unexpected'],
          type: 'error'
        })
      })
    }
  })
})

new Vue({
  store,
  router,
  icons,
  i18n,
  render: h => h(App)
}).$mount('#app')
